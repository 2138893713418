<template>
    <div class="row">
        <OFormControl :title="$t('Release Code')" class="col-12 col-md-6">
            <ODataLookup 
                :data-object="dsReleaseCodesLkp"
                class="form-control form-control-sm"
                :whereClause="lkpWhereClause"
                reloadOnWhereClauseChange
                :bind="handleSelect" 
                :value="releaseCode"
                display="NameAndTitle"
                :disabled="dsDoc.state.isLoading">
                <OColumn name="ID" width="80"/>
                <OColumn name="NameAndTitle" width="300"/>
            </ODataLookup>
        </OFormControl>
        <OFormControl :title="$t('Revision')" class="col-12 col-md-3">
            <input class="form-control form-control-sm" v-model="rev" @change="emitChange">  
        </OFormControl>
        <OFormControl :title="$t('Revision Date')" class="col-12 col-md-3">
            <ODatePicker date format="Short Date" v-model="revDate" :callback="()=>emitChange()" :clearable="false" clearableFromPicker class="form-control form-control-sm">
            </ODatePicker> 
        </OFormControl>
    </div>
    <div class="row mt-2">
        <!-- <div class="col-6"> -->
            <!-- <div class="form-check"> -->
                <!-- <input class="form-check-input" type="checkbox" v-model="copyContent" id="copyContent"/> -->
                <!-- <label class="form-check-label" :title="`${$t('Copy content (Files / Html) from this revision')}`" for="copyContent" style="top: -2px;position: relative;"> -->
                    <!-- {{$t('Copy Content')}} -->
                <!-- </label> -->
            <!-- </div> -->
        <!-- </div> -->
        <div class="col-6">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="copyAttachments" id="copyAttachments" @change="emitChange"/>
                <label class="form-check-label" :title="`${$t('Copy attachments from this revision')}`" for="copyAttachments" style="top: -2px;position: relative;">
                    {{$t('Copy Attachments')}}
                </label>
            </div>
        </div>
        <div class="col-6">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="copyDistribution" id="copyDistribution" @change="emitChange"/>
                <label class="form-check-label" :title="`${$t('If checked - distribution of current revision will be copied over for a new revision; if not checked - distribution will not be copied and will be auto-populated based on distribution setup')}`" for="copyDistribution" style="top: -2px;position: relative;">
                    {{$t('Copy Distributions')}}
                </label>
            </div>
        </div>
        <!-- <div class="col-6"> -->
            <!-- <div class="form-check"> -->
                <!-- <input class="form-check-input" type="checkbox" v-model="updateTagReplacement" id="updateTagReplacement"/> -->
                <!-- <label class="form-check-label" :title="`${$t('Will run tag replacement on the new revision (if file type is applicable)')}`" for="updateTagReplacement" style="top: -2px;position: relative;"> -->
                    <!-- {{$t('Update Tag Replacements')}} -->
                <!-- </label> -->
            <!-- </div> -->
        <!-- </div> -->
        <div class="col-6">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="copyOpenActions" id="copyOpenActions" @change="emitChange"/>
                <label class="form-check-label" :title="`${$t('Will move open actions to new revision and will close them on this document.')}`" for="copyOpenActions" style="top: -2px;position: relative;">
                    {{$t('Copy Open Actions')}}
                </label>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watch , onMounted} from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject'
    import { getOrCreateProcedure, context } from 'o365-modules';


    const props = defineProps({
        document_ID: Number,
        options: String
    });
    const emit = defineEmits(["optsChanged"]);
    const releaseCode = ref(""), releaseCode_ID = ref(""), rev = ref(""), revDate = ref(null), copyContent = ref(true), copyAttachments = ref(false), copyDistribution = ref(true), copyOpenActions = ref(false), updateTagReplacement = ref(true)

    const lkpWhereClause = computed(() => {
        return dsDoc.current.DocumentGroup_ID ? `(DocumentGroup_ID = ${dsDoc.current.DocumentGroup_ID} OR DocumentGroup_ID IS NULL) AND '${dsDoc.current.OrgUnitIdPath}' LIKE IdPath AND Closed IS NULL` : '1=2'
    });

    const dsDoc = getOrCreateDataObject({
        id: 'dsDoc_NewRevision',
        viewName: "aviw_Arena_Docs",
        //appId: props.dataObject.appId, 
        maxRecords: 1,
        selectFirstRowOnLoad: true,
        fields:
            [
                {name: "DocumentGroup_ID", type: "number" },
                {name: "OrgUnitIdPath", type: "string"}
            ]
    }), dsReleaseCodesLkp = getOrCreateDataObject({
        id: 'dsReleaseCodesLkp_NewRevision',
        viewName: "aviw_Arena_ReleaseCodesLookup",
        //appId: props.dataObject.appId, 
        maxRecords: 25,
        selectFirstRowOnLoad: true,
        fields:
            [
                {name: "ID", type: "number" },
                {name: "Name", type: "string"},
                {name: "Title", type: "string"},
                {name: "NameAndTitle", type: "string"}
            ]
    })

    watch(()=>props.document_ID, ()=>{
        //console.log("watch triggered")
        loadDoc();
    })
    watch(()=>props.options, ()=>{
        syncOptions();
    });

    const syncOptions = ()=>{
        if (props.options){
            let vOptions = JSON.parse(props.options);
            // console.log("watch triggered {options} - setting component values from row")
            // console.log(vOptions)
            // console.log(props.options)
            releaseCode.value = vOptions.ReleaseCode; 
            releaseCode_ID.value = vOptions.ReleaseCode_ID;
            rev.value = vOptions.Rev;
            revDate.value = vOptions.RevDate;
            //copyContent.value = vOptions.IncludeFile; 
            copyAttachments.value = vOptions.IncludeAttachments; 
            copyDistribution.value = vOptions.CopyDistribution;
            copyOpenActions.value = vOptions.IncludeOpenActions; 
            //updateTagReplacement.value = vOptions.updateTagReplacement;
        } else {
            // console.log("watch triggered {options} - setting default values")
            //set to default
            releaseCode.value = ""; 
            releaseCode_ID.value = "";
            rev.value = "";
            revDate.value = null;
            //copyContent.value = true; 
            copyAttachments.value = false; 
            copyDistribution.value = true;
            copyOpenActions.value = false; 
            //updateTagReplacement.value = true;
        }
    }

    const optionsJson = computed(()=>{
        let obj = {
            ReleaseCode: releaseCode.value, 
            ReleaseCode_ID: releaseCode_ID.value,
            Rev: rev.value,
            RevDate: revDate.value,
            //IncludeFile: copyContent.value, 
            IncludeAttachments: copyAttachments.value, 
            CopyDistribution: copyDistribution.value,
            IncludeOpenActions: copyOpenActions.value, 
            //updateTagReplacement: updateTagReplacement.value
        };

        return JSON.stringify(obj)
    });

    // watch(optionsJson, ()=>{
        // //console.log(optionsJson.value)
        // emit("optsChanged", optionsJson.value);
    // });

    const emitChange = ()=>{
        emit("optsChanged", optionsJson.value);
    }

    const procGetNextRev = new getOrCreateProcedure({ id: "procGetNextRev", procedureName: "astp_Arena_DocRevFindNextRevNumber" });
    const handleSelect = async(row) =>{
        releaseCode.value = row.NameAndTitle
        releaseCode_ID.value = row.ID

        const result = await procGetNextRev.execute({
                Document_ID: props.document_ID,
                ReleaseCode_ID: row.ID
        });
        
        if(result.Table.length > 0){
            rev.value = result.Table[0].Revision
        }
        emitChange();
    }

    const loadDoc = () =>{
        //console.log("Loading doc")
        //console.log(props.document_ID)
        dsDoc.recordSource.whereClause = props.document_ID ? `ID = ${props.document_ID}` : '1=2'
        dsDoc.load();//.then(()=>console.log(dsDoc.current));
    }

    onMounted(() => {
        if (!props.options){
            // console.log("New rev onMounted")
            emit("optsChanged", optionsJson.value);
        } else {
            syncOptions();
        }
        loadDoc();
    });

</script>